<!-- 新品管理 -->

<template>
  <div class="new">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="codeValue" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="brandValue" placeholder="请搜索品牌" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="selectValue" placeholder="审核状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <!-- <el-button class="btn" type="primary" @click="handleApply">申请新商品</el-button>
        <el-button class="btn" plain type="primary" @click="downloadTemplate">下载模板</el-button>
        <el-button class="btn" plain type="primary" @click="importShow = true">批量上传</el-button> -->
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="图片" width="110">
            <template slot-scope="scope">
              <el-image class="busilicense" :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''" fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="90" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="qualityDate" label="保质期(天)"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="suggestPrice" label="建议零售价(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="costPrice" label="供货价(元)"></el-table-column>
          <el-table-column min-width="160" show-overflow-tooltip prop="createdTime" label="创建时间"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="auditStatus" label="审核状态"></el-table-column>
          <el-table-column show-overflow-tooltip label="操作" header-align="center" align="center" width="190" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" plain v-if="scope.row.auditStatus == '审核不通过'" @click="handleCheck(scope.row)">查看</el-button>
              <el-button type="primary" plain v-if="scope.row.auditStatus == '审核不通过'" @click="handleReply(scope.row)">重新申请</el-button>
              <el-button type="primary" plain v-if="scope.row.auditStatus == '待审核'" @click="handleReply(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <!-- 查看审核不通过原因 -->
    <el-dialog title="审核不通过原因" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form label-width="160px" :model="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="审核不通过原因:">
              <!-- <el-input v-model="form.refuseReason" readonly></el-input> -->
              <div class="refuseReason">{{ form.refuseReason }}</div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 导入申请弹窗 -->
    <!-- <el-dialog title="导入" :visible.sync="importShow" :before-close="onImportCancel">
      <el-form label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="上传附件" required>
              <el-upload :action="apiBaseUrl" :headers="apiHeaders" :file-list="importFiles" accept=".xlsx,.xls"
                :on-change="beforeUpload" :auto-upload="false" :limit="1" :on-exceed="handleExceed"
                :on-remove="handleRemove">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="color: red;">提示：只能上传"xlsx"、"xls"文件，且不超过5MB</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onImportCancel">关 闭</el-button>
        <el-button type="primary" @click="submitFormImport('importRuleForm')">确 定</el-button>
      </span>
    </el-dialog> -->

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      // 下拉框数据
      selectValue: "",
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "2",
          label: "不通过",
        },
        {
          value: "1",
          label: "审核通过",
        },
      ],
      codeValue: "", // 请搜索条形码
      productValue: "", // 请搜索商品名称
      brandValue: "", // 搜索品牌
      // 表格数据
      tableData: [],
      // 图片预览
      showViewer: false,
      imgList: [],
      // 弹窗
      form: {
        refuseReason: "",
      },
      dialogVisible: false,
      // 导入弹窗数据
      apiBaseUrl: this.$axios.defaults.baseURL + this.$api.importNewProductApply,
      apiHeaders: {
        token: this.$store.state.token,
      },
      // importShow: false,
      importFiles: [], //文件列表
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getProductInfoSupplierList()
  },
  methods: {
    // 获取商品列表
    getProductInfoSupplierList() {
      this.loading = true
      this.$axios
        .get(this.$api.productExamineList, {
          params: {
            productName: this.productValue || null, // 商品名称
            brand: this.brandValue || null, // 品牌
            auditStatus: this.selectValue || null, // 审核状态
            barCode: this.codeValue || null, // 条形码（改动）
            page: this.currentPage,
            pageSize: this.pageSize,
            bizType: 3, //类型
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.list.map(item => {
              return {
                ...item,
                auditStatus: item.auditStatus == 0 ? "待审核" : item.auditStatus == 1 ? "审核通过" : "审核不通过",
              }
            })
            this.tableData = list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleCheck(row) {
      this.dialogVisible = true
      this.form.refuseReason = row.auditMark
    },
    onCancel() {
      this.dialogVisible = false
    },
    // 模板下载
    // downloadTemplate() {
    //   const link = document.createElement("a");
    //   link.style.display = "none";
    //   link.href = "https://xn--fcs331b8yd3xj5in.com/files/default/商品批量申请模版.xlsx";
    //   link.download = "批量申请模版.xlsx";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },
    // 导入弹窗关闭
    // onImportCancel() {
    //   this.importShow = false;
    //   this.importFiles = [];
    // },
    // beforeUpload(file) {
    //   console.log(file);
    //   const suffixArr = ["xlsx", "xls"];
    //   const maxSize = 1024 * 1024 * 5;
    //   if (!suffixArr.includes(file.name.split(".").pop())) {
    //     this.$message.error(`只能上传"xlsx"、"xls"文件`);
    //     this.importFiles = [];
    //     return false;
    //   }
    //   if (file.size > maxSize) {
    //     this.$message.error("附件不能超过5M");
    //     this.importFiles = [];
    //     return false;
    //   }
    //   this.importFiles.push(file.raw)
    // },
    // handleExceed() {
    //   this.$message.warning(`只能上传一个文件！`);
    // },
    // handleRemove() {
    //   this.importFiles = [];
    // },
    // // 导入表单校验
    // submitFormImport() {
    //   if (!this.importFiles || this.importFiles.length == 0) {
    //     return this.$message.warning('请先上传文件');
    //   }
    //   let parmas = new FormData()
    //   parmas.append('file', this.importFiles[0])
    //   parmas.append('type', 1)
    //   this.$axios.post(this.$api.importNewProductApply, parmas).then((res) => {
    //     if (res.data.code == 100) {
    //       this.$message.success('导入成功!');
    //       this.onImportCancel();
    //       this.getProductInfoSupplierList();
    //     }
    //   })
    // },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 下拉选择
    handleSelect(value) {
      this.clearData()
      this.selectValue = value
      this.getProductInfoSupplierList()
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getProductInfoSupplierList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData()
      this.pageSize = e
      this.getProductInfoSupplierList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getProductInfoSupplierList()
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 13) {
        if (row.auditStatus == "待审核") {
          return { color: "#E35B5A" }
        }
      }
    },
    // 申请新商品
    // handleApply() {
    //   this.$router.push('detail/apply');
    // },
    // 重新申请
    handleReply(row) {
      this.$router.push({ path: "detail/apply", query: { id: row.id } })
    },
    // // 图片预览
    // previewImage(url) {
    //   this.imgList = url
    //   this.showViewer = true
    // },
  },
}
</script>

<style scoped lang="scss">
.new {
  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .el-input {
      width: 220px;
      margin-bottom: 20px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .refuseReason {
    width: 600px;
    height: 40px;
    border: 1px solid #e5e7e6;
    border-radius: 10px;
    padding: 0 10px;
  }

  .busilicense {
    display: block;
    width: 80px;
    height: 58px;
    border-radius: 4px;
  }
}
</style>
